import { VendorCategory } from 'utils/vendors';
import { ENV_STAGE } from 'helpers';

const isPathHidden = (features, route) => route.feature && !features[route.feature];
const matchCategory = (vendor, route) => route.category && VendorCategory[vendor] === route.category;

// For totally hiding the route from sidebar.
export const filterByFeatures = (client) => (route) => !isPathHidden(client.features, route);

// ignore filter if route isn't category related
export const filterByCategory = (client) => (route) => {
  if (!route.category) {
    return true;
  }
  return matchCategory(client.vendor, route);
};

export const filterForMainStore = () => (route) => {
  const hideList = [
    '/members',
    '/members/tags',
    '/members/group',
    '/message_center/group_message',
    '/message_center/patient_tracking',
    '/message_center/templates',
    '/chat',
  ];
  return !hideList.includes(route.url);
};

export const filterByVendor = (client) => (route) => {
  const vendor = client.vendor;
  const hideListByVendor = {
    medley: [
      '/linemessage', // LINE傳送訊息
      '/appointments', // 預約中心
      '/westernappointments', // 掛號中心
      '/message', // 發送訊息
      '/chat', // 線上諮詢
    ],
    linkedcare: [
      '/linemessage', // LINE傳送訊息
      '/appointments', // 預約中心
      '/westernappointments', // 掛號中心
      '/message', // 發送訊息
      '/chat', // 線上諮詢
      '/passcode', // 手機驗證
    ],
  };

  if (hideListByVendor[vendor]?.includes(route.url)) {
    return false;
  }

  return true;
};

// @TODO: remove this filter after feature ready
export const filterByBetaClients = (client) => (route) => {
  const betaClientIds = [
    'a9adc630-79cc-11ec-9e41-d70b2ee79e80', // 小宇宙
    'f129d6d0-32e6-11eb-9d46-bf7dbeb062aa', // 彤學牙醫
    'abcf57c0-e45b-11e9-b759-937f3407d1cb', // 天晴牙醫
    '1c06bd60-4122-11ec-a382-ed5514f8425b', // 璽宇令和牙醫
    '28301c40-87a4-11eb-a04f-27ac74f7ceae', // 循映牙醫
    '80edd060-3e8b-11ef-8291-350cfa6d6617', // 悅健耳鼻喉科
    '8417f400-2514-11ee-a4b2-55cc98db08fe', // 麥建方診所
    '76370bc0-5b59-11ee-a3db-c162fede01d4', // 大佑診所
    '7ddf74b0-59c1-11ed-b1ee-9909057cd7db', // 安成中醫
    '3343fa60-099a-11ed-8d25-45f5f04effe8', // 宇泰復健科
  ];

  if (ENV_STAGE === 'production' && !betaClientIds.includes(client.id) && route.url === '/flexEditor') {
    return false;
  }

  return true;
};
