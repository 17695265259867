import styled from 'styled-components';
import { dashboard_v2 } from 'theme';

const { colors } = dashboard_v2;

const defaultColor = colors.PRIMARY_500;

const fontSizeMap = {
  xxs: '11px',
  xs: '13px',
  sm: '14px',
  md: '15px',
  lg: '18px',
  xl: '22px',
};

const offsetMap = {
  xxl: '20px',
  xl: '16px',
  lg: '12px',
  md: '8px',
  sm: '4px',
};

export const marginMap = {
  lg: '12px',
  xxl: '20px',
  none: 0,
};

const colorCheck = (color) => ((color || '').charAt(0) === '#' ? color : 'unset');

export const OuterBox = styled.div`
  display: flex;
  flex-direction: ${(props) => {
    if (props.layout === 'baseline' || props.layout === 'horizontal') {
      return 'row';
    } else if (props.layout === 'vertical') {
      return 'column';
    }
  }};
  width: 100%;
  padding: ${(props) => (props.type === 'image' ? '0px' : '16px')};
  background-color: ${(props) =>
    props.backgroundColor === '{{myColor}}' ? defaultColor : colorCheck(props.backgroundColor)};
`;

export const BodyOuterBox = styled.div`
  display: flex;
  flex-direction: ${(props) => {
    if (props.layout === 'baseline' || props.layout === 'horizontal') {
      return 'row';
    } else if (props.layout === 'vertical') {
      return 'column';
    }
  }};
  width: 100%;
  align-items: ${(props) => props.alignItems || 'unset'};
  justify-content: ${(props) => props.justifyContent || 'unset'};
  padding: 10px 16px;
  background-color: ${(props) => colorCheck(props.backgroundColor)};
`;

export const HeroOuterBox = styled.div`
  display: flex;
  flex-direction: ${(props) => {
    if (props.layout === 'baseline' || props.layout === 'horizontal') {
      return 'row';
    } else if (props.layout === 'vertical') {
      return 'column';
    }
  }};
  width: 100%;
  background-color: ${(props) => colorCheck(props.backgroundColor)};
`;

export const FooterOuterBox = styled.div`
  display: flex;
  flex-direction: ${(props) => {
    if (props.layout === 'baseline' || props.layout === 'horizontal') {
      return 'row';
    } else if (props.layout === 'vertical') {
      return 'column';
    }
  }};
  width: 100%;
  margin-top: auto;
  align-items: ${(props) => props.alignItems || 'unset'};
  justify-content: ${(props) => props.justifyContent || 'unset'};
  padding: 10px 16px;
  background-color: ${(props) => colorCheck(props.backgroundColor)};
`;

export const ContentBox = styled.div`
  display: flex;
  position: relative;
  margin-top: 3px;
  flex-direction: ${(props) => {
    if (props.layout === 'baseline' || props.layout === 'horizontal') {
      return 'row';
    } else if (props.layout === 'vertical') {
      return 'column';
    }
  }};
  background-color: ${(props) =>
    props.backgroundColor === '{{myColor}}' ? defaultColor : colorCheck(props.backgroundColor)};
  border-color: ${(props) => props.borderColor || '#ffffff'};
  border-style: solid;
  border-width: ${(props) => (props.borderWidth === 'none' || !props.borderWidth ? '0px' : '1.5px')};
  border-radius: ${(props) =>
    props.cornerRadius ? cornerRadiusMap[props.cornerRadius] || props.cornerRadius : 'unset'};
  flex: ${(props) => {
    if (props.height) {
      return '1 0 auto';
    } else if (props.flex && props.flex !== 0) {
      return props.flex;
    } else {
      return '0 0 auto';
    }
  }};
  justify-content: ${(props) => props.justifyContent || 'unset'};
  height: ${(props) => props.height || 'unset'};
  width: ${(props) => props.width || 'unset'};
  align-items: ${(props) => props.alignItems || 'unset'};
  overflow: hidden;
`;

export const ContentTextContainer = styled.div`
  display: flex;
  position: relative;
  padding: 0 3px;
  font-size: ${(props) => fontSizeMap[props.size] || 'inherit'};
  top: ${(props) => offsetMap[props.offsetTop] || props.offsetTop || 'inherit'};
  width: 100%;
  flex: ${(props) => (props.flex === 0 ? '0' : props.flex || 'unset')};
  text-align: ${(props) => props.align || 'unset'};
  color: ${(props) => colorCheck(props.color)};
  justify-content: ${(props) => props.align || 'unset'};
  align-items: ${(props) => props.gravity || 'unset'};
`;

export const ContentTextP = styled.p`
  margin: 0;
  max-width: 100%;
  font-size: ${(props) => fontSizeMap[props.size] || 'inherit'};
  font-weight: ${(props) => props.weight || 'normal'};
  line-height: 1.4;
  text-align: ${(props) => props.align || 'left'};
  overflow: hidden;
  color: ${(props) =>
    props.color === '{{myColor}}' ? defaultColor : !props.color ? '#000000' : colorCheck(props.color)};
  white-space: ${(props) => (props.wrap ? 'normal' : 'nowrap')};
  align-items: ${(props) => props.gravity || 'unset'};
  word-wrap: ${(props) => (props.wrap ? 'break-word' : 'inherit')};
`;

export const ContentSpan = styled.span`
  margin: 0;
  font-size: ${(props) => fontSizeMap[props.size] || 'inherit'};
  font-weight: ${(props) => props.weight || 'inherit'};
  line-height: 1.4;
  color: ${(props) => colorCheck(props.color) || 'inherit'};
`;

export const ContentButton = styled.div`
  display: flex;
  width: 100%;
  height: ${(props) => buttonHeightMap[props.height] || '52px'};
  justify-content: center;
  align-items: center;
  // flex: ${(props) => props.flex || 'unset'};
  color: ${(props) => {
    if (props.buttonStyle === 'primary') {
      return '#ffffff';
    } else if (props.buttonStyle === 'secondary') {
      return '#000000';
    } else return `${colorCheck(props.color)}`;
  }};
  background-color: ${(props) => {
    if (props.buttonStyle === 'primary' || props.buttonStyle === 'secondary') {
      return `${props.color}`;
    } else return 'inherit';
  }};
`;

export const Spacer = styled.div`
  height: 16px;
  width: 16px;
`;

export const ContentSeparater = styled.div`
  width: 100%;
  height: 1px;
  background-color: #d9d9d9;
`;

export const sizeMap = {
  full: '100%',
  '5xl': '220px',
  '4xl': '200px',
  '3xl': '180px',
  '2xl': '160px',
  xl: '140px',
  lg: '120px',
  md: '100px',
  sm: '80px',
  xs: '60px',
  xxs: '40px',
};

export const aspectModeMap = {
  cover: 'cover',
  fit: 'contain',
};

export const gravityMap = {
  center: 'center',
  top: 'flex-start',
  bottom: 'flex-end',
};

export const alignMap = {
  center: 'center',
  start: 'flex-start',
  end: 'flex-end',
};

export const buttonHeightMap = {
  sm: '40px',
  md: '52px',
};

export const cornerRadiusMap = {
  xs: '3px',
  sm: '5px',
  md: '8px',
  lg: '12px',
  xl: '16px',
  xxl: '20px',
};
export const aspectRatioMap = {
  '16_9': '16:9',
  '4_1': '4:1',
  '1_1': '1:1',
};

export const gravityOptions = Object.keys(gravityMap);
export const alignOptions = Object.keys(alignMap);
export const sizeOptions = Object.keys(sizeMap);
export const aspectModeOptions = Object.keys(aspectModeMap);
export const aspectRatioOptions = Object.keys(aspectRatioMap);
export const marginOptions = Object.keys(marginMap);
export const fontSizeOptions = Object.keys(fontSizeMap);
export const buttonHeightOptions = Object.keys(buttonHeightMap);

export const ImageContainer = styled.div`
  margin-top: ${(props) => marginMap[props.margin] || 0};
  justify-content: ${(props) => gravityMap[props.gravity] || gravityMap.center};
  align-items: ${(props) => alignMap[props.align] || alignMap.center};
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const ImageWrap = styled.div`
  width: ${(props) => sizeMap[props.size] || props.size || '400px'};
  max-width: 100%;
`;

export const Imagea = styled.a`
  display: block;
  position: relative;
  width: 100%;
  padding-bottom: ${(props) => props.ratio || 'unset'};
`;

export const Image = styled.span`
  background-image: url('${(props) => props.url}');
  background-size: ${(props) => aspectModeMap[props.aspectMode] || 'contain'};
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center center;
`;

export const PureImage = styled.img`
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  overflow: hidden;
  position: relative;
  z-index: -1;
`;
