import { getInfo, isAuthRoleBrand, isOperationType } from 'helpers/authHelpers';
import { operationEnabled } from 'components/Sidebar/_operationNav';
import defaultNav, { demoNav } from 'components/Sidebar/_nav';
import { setNav } from 'reducers/nav';
import { demoMiddleWare } from 'demo';
import { isValidBlackList } from 'helpers/customerHelpers';
import { filterByCategory, filterByFeatures, filterByVendor, filterByBetaClients } from 'components/Sidebar/pathRule';
import { updateAccount, AUTH_LOGOUT } from './auth';
import { fetchClientById, fetchClientsByIds, updateBlackListApi, fetchDataStudioLink } from '../lib/clientService';
import { getConsumption } from '../lib/lineService';
import { t } from 'i18n/config';

const SET_CLIENT = 'SET_CLIENT';
const SET_BRAND_STORES = 'SET_BRAND_STORES';
const UPDATE_BLACK_LIST = 'UPDATE_BLACK_LIST';
const UPDATE_GOOGLE_TOKEN = 'CLIENTS/UPDATE_GOOGLE_TOKEN';
const UPDATE_GOOGLE_ACCOUNT_INFO = 'CLIENTS/UPDATE_GOOGLE_ACCOUNT_INFO';
const UPDATE_GOOGLE_ACCOUNT_LOCATION = 'CLIENTS/UPDATE_GOOGLE_ACCOUNT_LOCATION';
const SET_PATIENT_TAGS = 'CLIENTS/SET_PATIENT_TAGS';
const UPDATE_LINE_CONSUMPTION = 'CLIENTS/UPDATE_LINE_CONSUMPTION';
export const SET_SELECTED_STORE = 'SET_SELECTED_STORE';

let interval = null;

export const defaultState = {
  byId: {},
  selectedIds: [],
};

export const updateBlackListActionCreator = ({ clientId, blackList }) => ({
  type: UPDATE_BLACK_LIST,
  payload: {
    clientId,
    blackList,
  },
});

export const updateLineConsumption = ({ clientId, usage }) => ({
  type: UPDATE_LINE_CONSUMPTION,
  payload: {
    clientId,
    usage,
  },
});

export const setClient = (client) => ({
  type: SET_CLIENT,
  payload: {
    user: client,
  },
});

export const setPatientTags = ({ clientId, tags }) => ({
  type: SET_PATIENT_TAGS,
  payload: {
    clientId,
    tags,
  },
});

export const updateClientGoogleAccountLocation = ({
  id,
  googleAccountId,
  googleAccountName,
  googleLocationId,
  googleLocationName,
}) => ({
  type: UPDATE_GOOGLE_ACCOUNT_LOCATION,
  payload: {
    id,
    googleAccountId,
    googleAccountName,
    googleLocationId,
    googleLocationName,
  },
});

export const updateClientGoogleAccountToken = ({ id, googleAccountAccessToken }) => ({
  type: UPDATE_GOOGLE_TOKEN,
  payload: {
    id,
    googleAccountAccessToken,
  },
});

export const updateClientGoogleAccountInfo = ({ id, googleAccountId, googleAccountName }) => ({
  type: UPDATE_GOOGLE_ACCOUNT_INFO,
  payload: {
    id,
    googleAccountId,
    googleAccountName,
  },
});

const setBrandStores = (normalizeClients) => ({
  type: SET_BRAND_STORES,
  payload: normalizeClients,
});

export const setSelectedStores = (clientIds) => ({
  type: SET_SELECTED_STORE,
  payload: clientIds,
});

export const addBlackList =
  ({ clientId, name, phone, email }) =>
  async (dispatch, getState) => {
    if (
      !isValidBlackList({
        email,
        phone,
      })
    ) {
      alert(t('incorrectPhoneNumberOrEmail'));
      return;
    }
    const { byId } = getState().clients;
    const blackList = (byId[clientId].blackList || []).concat(`${name}:${phone}:${email}`);

    const clients = getState().clients.selectedIds.map((id) => getState().clients.byId[id]);
    await demoMiddleWare(
      clients,
      {
        clientId,
        blackList,
      },
      updateBlackListApi
    );
    dispatch(
      updateBlackListActionCreator({
        clientId,
        blackList,
      })
    );
  };

export const removeBlackList =
  ({ clientId, phone, email }) =>
  async (dispatch, getState) => {
    const { byId } = getState().clients;
    const clients = getState().clients.selectedIds.map((id) => getState().clients.byId[id]);
    let index;
    const newBlackList = byId[clientId].blackList || [];
    (byId[clientId].blackList || []).forEach((str, i) => {
      if ((phone !== '' && str.includes(phone)) || (email !== '' && str.includes(email))) {
        index = i;
      }
    });
    newBlackList.splice(index, 1);
    await demoMiddleWare(
      clients,
      {
        clientId,
        blackList: newBlackList,
      },
      updateBlackListApi
    );
    dispatch(
      updateBlackListActionCreator({
        clientId,
        blackList: newBlackList,
      })
    );
  };

const getConsumptionByClient = async (client, dispatch) => {
  let linecount = 0;
  switch (true) {
    case isOperationType(client):
      {
        const { totalUsage } = await getConsumption({ clientId: client.id });
        dispatch(updateLineConsumption({ clientId: client.id, usage: totalUsage }));
        linecount = totalUsage;
      }
      break;
    case isAuthRoleBrand(client):
      // do nothing
      break;
    case client.demo:
      dispatch(updateLineConsumption({ clientId: client.id, usage: 133 }));
      linecount = 133;
      break;
    case client != null:
      {
        const { totalUsage } = await getConsumption({ clientId: client.id });
        dispatch(updateLineConsumption({ clientId: client.id, usage: totalUsage }));
        linecount = totalUsage;
      }
      break;
    default:
      break;
  }
  const newclient = {
    ...client,
    linecount,
  };
  return newclient;
};

const registerBackgroundTask = (client, dispatch) => {
  interval = setInterval(() => {
    getConsumptionByClient(client, dispatch).catch(() => {});
  }, 60000);
};

export const getDataStudioLink = async ({ clientId }) => {
  const url = await fetchDataStudioLink({ clientId });
  return url;
};

export const fetchClient = () => async (dispatch, getState) => {
  const clientId = getInfo().id;
  let client = await fetchClientById(clientId);
  if (client == null || Object.keys(client).length === 0) {
    throw new Error(t('pleaseReLogin'));
  }

  if (client.active === false) {
    throw new Error(t('thisAccountHasExpired'));
  }

  dispatch(updateAccount(client));
  const { items } = defaultNav;
  const nav = { items };
  nav.items = nav.items
    .filter(filterByFeatures(client))
    .filter(filterByCategory(client))
    .filter(filterByVendor(client))
    .filter(filterByBetaClients(client)) // @TODO: remove this filter after feature ready
    .map((route) => {
      if (!client.features.appointNotification && route.url === '/appointments') {
        if (window.location.pathname === route.url) {
          window.location.pathname = '/';
        }
        return {
          ...route,
          url: '/',
          icon: 'icon-ban',
          enabled: false,
          badge: {
            variant: 'danger',
            text: 'PRO',
          },
        };
      }

      if (
        route.url === '/linemessage' &&
        (!client.features.message || (client.features.message && client.features.userManagement))
      ) {
        console.log(client.features);
        if (window.location.pathname === route.url) {
          window.location.pathname = '/';
        }
        return { ...route, url: '/', hidden: true };
      }

      if (!client.features.message && route.url === '/message') {
        if (window.location.pathname === route.url) {
          window.location.pathname = '/';
        }
        return {
          ...route,
          url: '/',
          icon: 'icon-ban',
          enabled: false,
          badge: {
            variant: 'danger',
            text: 'PRO',
          },
        };
      }

      if (!client.features.chat && route.url === '/chat') {
        if (window.location.pathname === route.url) {
          window.location.pathname = '/';
        }
        return {
          ...route,
          url: '/',
          icon: 'icon-ban',
          enabled: false,
          badge: {
            variant: 'danger',
            text: 'PRO',
          },
        };
      }
      return route;
    });

  if (isOperationType(client)) {
    // replace client with admin client. op only for auth login.
    client = await fetchClientById(client.adminStoreId);
    nav.items = nav.items.filter((route) => operationEnabled[route.url]);
    dispatch(setNav(nav));
  } else if (isAuthRoleBrand(client)) {
    const clients = await fetchClientsByIds(client.bindingStoreIds.join(','));
    const normalizeClients = clients.reduce((sum, targetClient) => ({ ...sum, [targetClient.id]: targetClient }), {});
    dispatch(setBrandStores(normalizeClients));
    const { items: navItems } = getState().nav;
    if (navItems.length === 0) {
      const brandName = clients[0].brands || t('selectBranch');
      const children = [
        {
          name: t('viewAll'),
          icon: 'icon-cursor',
          button: '1',
          clientIds: clients.map(({ id }) => id),
        },
      ].concat(
        clients.map(({ id, companyName }) => ({
          name: companyName,
          icon: 'icon-cursor',
          button: '1',
          clientIds: [id],
        }))
      );
      dispatch(
        setNav({
          items: [
            {
              title: true,
              name: t('chainOptions'),
            },
            {
              name: brandName,
              icon: 'icon-cursor',
              children,
            },
            {
              title: true,
              name: t('operationFunctions'),
            },
          ].concat(nav.items),
        })
      );
    }
  } else {
    switch (client.demo) {
      case true:
        dispatch(setNav(demoNav));
        break;
      default:
        dispatch(setNav(nav));
        break;
    }
  }
  client = await getConsumptionByClient(client, dispatch).catch(() => client);
  dispatch(setClient(client));
  if (!interval) {
    registerBackgroundTask(client, dispatch);
  }
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_CLIENT:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.user.id]: {
            ...action.payload.user,
          },
        },
        selectedIds: state.selectedIds.length > 0 ? state.selectedIds : [action.payload.user.id],
      };
    case SET_BRAND_STORES:
      return {
        ...state,
        byId: {
          ...state.byId,
          ...action.payload,
        },
        selectedIds: state.selectedIds.length > 0 ? state.selectedIds : Object.keys(action.payload),
      };
    case SET_SELECTED_STORE:
      return {
        ...state,
        selectedIds: action.payload,
      };
    case UPDATE_BLACK_LIST: {
      const { clientId, blackList } = action.payload;
      return {
        ...state,
        byId: {
          ...state.byId,
          [clientId]: {
            ...state.byId[clientId],
            blackList,
          },
        },
      };
    }
    case UPDATE_LINE_CONSUMPTION: {
      const { clientId, usage } = action.payload;
      return {
        ...state,
        byId: {
          ...state.byId,
          [clientId]: {
            ...state.byId[clientId],
            linecount: usage,
          },
        },
      };
    }
    case SET_PATIENT_TAGS: {
      const { clientId, tags } = action.payload;
      return {
        ...state,
        byId: {
          ...state.byId,
          [clientId]: {
            ...state.byId[clientId],
            tags,
          },
        },
      };
    }
    case UPDATE_GOOGLE_TOKEN: {
      const { id, googleAccountAccessToken } = action.payload;
      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: {
            ...state.byId[id],
            googleAccountAccessToken,
          },
        },
      };
    }
    case UPDATE_GOOGLE_ACCOUNT_INFO: {
      const { id, googleAccountId, googleAccountName } = action.payload;
      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: {
            ...state.byId[id],
            googleAccountName,
            googleAccountId,
          },
        },
      };
    }
    case UPDATE_GOOGLE_ACCOUNT_LOCATION: {
      const { id, googleAccountId, googleAccountName, googleLocationId, googleLocationName } = action.payload;
      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: {
            ...state.byId[id],
            googleAccountName,
            googleAccountId,
            googleLocationId,
            googleLocationName,
          },
        },
      };
    }
    case AUTH_LOGOUT:
      return defaultState;
    default:
      return state;
  }
};
