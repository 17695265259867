import React, { useState } from 'react';
import { useEffect } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import FlexRenderer from './templates/MessageRenderer';
import Tree from './Tree/Tree';
import FlexElementEditor, { mainKeys } from './Editor';
import Button from 'components/Dashboard_v2/Button';
import Text from 'components/Dashboard_v2/Text';
import { dashboard_v2 } from 'theme';
import useAlert from 'hooks/useAlert';
import { t } from 'i18n/config';

const { colors } = dashboard_v2;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  width: 98%;
  max-width: 1100px;
`;

const ChildOfSimulatorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  width: ${(props) => props.width || 'inherit'}%;
`;

const MessageContainer = styled.div`
  background-color: #849ebf;
  max-width: 400px;
  min-height: 480px;
  padding: 12px 0px;
  border: 1px solid ${colors.SHADES_400};
  border-radius: 6px;
  overflow: hidden;
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 16px;
`;

const defaultWorkingContent = {
  content: {},
  path: '',
  index: 0,
  parentPath: '',
};

const defaultMainStyles = {};

const orderContent = (workingJSON) => {
  const newJSON = JSON.parse(JSON.stringify(workingJSON));
  mainKeys.forEach((key) => {
    if (workingJSON[key]) {
      delete newJSON[key];
      newJSON[key] = workingJSON[key];
    }
  });
  return newJSON;
};

function FlexSimulator({ flexJSON, onSave }) {
  const [workingJSON, setWorkingJSON] = useState(flexJSON.contents && orderContent(flexJSON.contents));
  const [workingContent, setWorkingContent] = useState(defaultWorkingContent);
  const [isSaved, setIsSaved] = useState(true);
  const [isValidColor, setIsValidColor] = useState(true);
  const [isEmptyText, setIsEmptyText] = useState(false);
  const [isValidRatio, setIsValidRatio] = useState(true);

  const { renderAlert, setAlert } = useAlert();

  const checkFlexIsSaved = () => {
    console.log('orderContent(flexJSON.contents):', orderContent(flexJSON.contents));
    console.log('workingJSON:', orderContent(workingJSON));
    return _.isEqual(orderContent(flexJSON.contents), workingJSON);
  };

  const handleValidColor = (isValid) => {
    setIsValidColor(isValid);
  };

  const handleValidRatio = (isValid) => {
    setIsValidRatio(isValid);
  };
  const handleEmptyTextStatus = (emptyText) => {
    setIsEmptyText(emptyText);
  };

  const onSelect = ({ content, path, parentPath, index }) => {
    setWorkingContent({
      content,
      path,
      parentPath,
      index,
    });
    console.log('path:', path);
    console.log('parentPath:', parentPath);
    console.log('content.type:', content.type);
  };
  const handleSaveFlexMessage = () => {
    onSave({
      altText: flexJSON.altText,
      contents: workingJSON,
      type: flexJSON.type,
    });
    setIsSaved(true);
  };

  const handleContentChange = ({ path, content, parentPath, index }) => {
    const newJSON = _.set(_.cloneDeep(workingJSON), `${path}`, content);
    setWorkingJSON(newJSON);
    setWorkingContent({
      path,
      content,
      parentPath,
      index,
    });
  };

  const handleRemoveMainKey = () => {
    const newJSON = JSON.parse(JSON.stringify(workingJSON));
    let selectedBubbleIndex, selectedMainKey, remainingMainKeys;
    const getRemainingMainKeys = (object) => Object.keys(object).filter((path) => mainKeys.includes(path));
    if (!workingJSON[workingContent.path]) {
      const selectedBubble = workingContent.path.match(/contents\[(\d+)\]/);
      selectedBubbleIndex = parseInt(selectedBubble[1], 10);
      selectedMainKey = workingContent.path.split('.')[1];
      remainingMainKeys = getRemainingMainKeys(newJSON.contents[selectedBubbleIndex]);

      if (remainingMainKeys.length > 1) {
        delete newJSON.contents[selectedBubbleIndex][selectedMainKey];
      } else {
        setAlert({ type: 'warning', title: t('flexEditor.canNotDeleteLast') });
        return;
      }
    } else {
      // bubble
      remainingMainKeys = getRemainingMainKeys(newJSON);

      if (remainingMainKeys.length > 1) {
        delete newJSON[workingContent.path];
      } else {
        setAlert({ type: 'warning', title: t('flexEditor.canNotDeleteLast') });
        return;
      }
    }
    setWorkingJSON(newJSON);
    setWorkingContent(defaultWorkingContent);
  };

  const handleRemoveBubble = () => {
    if (workingJSON.contents.length <= 1) {
      setAlert({ type: 'warning', title: t('flexEditor.canNotDeleteLast') });
      return;
    } else {
      const newJSON = _.cloneDeep(workingJSON);
      const selectedBubble = workingContent.path.match(/contents\[(\d+)\]/);
      console.log('selectedBubble:', selectedBubble);
      const selectedBubbleIndex = parseInt(selectedBubble[1], 10);
      newJSON.contents.splice(selectedBubbleIndex, 1);
      setWorkingJSON(newJSON);
      setWorkingContent(defaultWorkingContent);
    }
  };

  const handleChangeStyles = ({ styles, path }) => {
    setWorkingJSON({
      ...workingJSON,
      styles: {
        ...workingJSON.styles,
        [path]: {
          ...styles,
        },
      },
    });
  };

  const handleRemove = ({ parentPath, index }) => {
    const parentContent = _.get(workingJSON, parentPath);
    const parentContentCopy = JSON.parse(JSON.stringify(parentContent));
    if (parentContentCopy.contents.length === 1) {
      setAlert({ type: 'warning', title: t('flexEditor.canNotDelete') });
      return;
    }
    parentContentCopy.contents = parentContentCopy.contents.filter((c, i) => i !== index);
    const newJSON = _.set(_.cloneDeep(workingJSON), parentPath, parentContentCopy);
    setWorkingJSON(newJSON);
    setWorkingContent(defaultWorkingContent);
  };

  useEffect(() => {
    setWorkingJSON(orderContent(flexJSON.contents));
    setWorkingContent(defaultWorkingContent);
  }, [flexJSON]);

  useEffect(() => {
    setIsSaved(checkFlexIsSaved());
    console.log('isSaved:', checkFlexIsSaved());
  }, [JSON.stringify(workingJSON)]);

  return (
    <Container>
      {renderAlert({ position: 'fixed', top: '8%' })}
      <ChildOfSimulatorWrapper width={38}>
        <Text font="Heading/Large/Medium" marginBottom={12} color="SHADES_700">
          {t('previewMessage')}
        </Text>
        <MessageContainer className="MessageContainer">
          <FlexRenderer json={workingJSON}></FlexRenderer>
        </MessageContainer>
        <ActionButtons>
          <Button
            color="primary"
            variant="outline"
            onClick={() => {
              handleSaveFlexMessage();
            }}
            disabled={!isValidColor || !isValidRatio || isEmptyText || isSaved}
          >
            {t('flexEditor.saveFlexMessage')}
          </Button>
        </ActionButtons>
      </ChildOfSimulatorWrapper>
      <ChildOfSimulatorWrapper width={35}>
        <Text font="Heading/Large/Medium" marginBottom={12} color="SHADES_700">
          {t('flexEditor.chooseComponentOfTree')}
        </Text>
        {flexJSON.contents && <Tree json={workingJSON} onSelect={onSelect} selectedPath={workingContent.path} />}
      </ChildOfSimulatorWrapper>
      <ChildOfSimulatorWrapper width={25}>
        <Text font="Heading/Large/Medium" marginBottom={12} color="SHADES_700">
          {t('flexEditor.editComponent')}
        </Text>
        <FlexElementEditor
          key={workingContent.path}
          path={workingContent.path}
          content={workingContent.content}
          index={workingContent.index}
          parentPath={workingContent.parentPath}
          onChange={handleContentChange}
          onRemove={handleRemove}
          onChangeStyles={handleChangeStyles}
          mainStyles={workingJSON.styles || defaultMainStyles}
          onRemoveMainKey={handleRemoveMainKey}
          onRemoveBubble={handleRemoveBubble}
          onValidColorChange={handleValidColor}
          onTextEmptyStatus={handleEmptyTextStatus}
          onValidRatio={handleValidRatio}
        ></FlexElementEditor>
      </ChildOfSimulatorWrapper>
    </Container>
  );
}

export default FlexSimulator;
