import { useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import SendIcon from '@material-ui/icons/Send';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';
import InfoPopover from 'components/Dashboard_v2/InfoPopover';
import { t } from 'i18n/config';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 190,
    height: '100%',
    backgroundColor: theme.palette.background.paper,
    position: 'fixed',
    zIndex: 3,
    overflow: 'auto',
    paddingBottom: '160px',
    '& i': {
      fontSize: '16px',
      marginLeft: theme.spacing(1),
    },
    '& span': {
      overflowWrap: 'break-word',
    },
  },
  nested: {
    paddingLeft: theme.spacing(2.3),
  },
  listSubheader: {
    cursor: 'pointer',
  },
}));

const keywords = {
  excludeKeywords: ['ContentFormat', 'Alert', '綁定帳號', '約診紀錄查詢', 'Contentformat', 'ReviewTemplate'],
  appointmentMessageKeywords: ['ContentFormat0', 'ContentFormat1', 'ContentFormat5', 'ContentFormat7'],
};

const standardizeDefaultAction = (defaultReminder) => {
  const standardizedAction = {
    id: 0,
    alias: '',
    lineChannelID: 0,
    message: {},
    messageTmpl: '',
    quickReply: {},
    smsMessage: {},
  };
  standardizedAction.alias = defaultReminder.Alias.String;
  standardizedAction.message = defaultReminder.Message.RawMessage;
  standardizedAction.quickReply = defaultReminder.QuickReply.RawMessage;
  standardizedAction.smsMessage = defaultReminder.SmsMessage.RawMessage;
  standardizedAction.messageTmpl = defaultReminder.MessageTmpl.String;

  return standardizedAction;
};

const combineReminderActionList = (defautlReminderList, updatedReminderList) => {
  const UpdatedMap = updatedReminderList.reduce((acc, item) => {
    acc[item.alias] = item;
    return acc;
  }, {});

  const result = defautlReminderList.map((item) => {
    if (UpdatedMap[item.alias]) {
      return UpdatedMap[item.alias];
    }
    return item;
  });
  return result;
};

const filterAndSortAction = (lineActions) => {
  const updatedList = lineActions?.updated || lineActions;
  const defaultList = lineActions?.default || lineActions;

  const filterAndSort = (list, keywords, shouldInclude) => {
    return list
      .filter((item) => {
        const aliasValue = item.alias || item.Alias.String;
        return shouldInclude
          ? keywords.some((keyword) => aliasValue === keyword)
          : !keywords.some((keyword) => aliasValue?.includes(keyword));
      })
      .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
  };
  // default 的預約提醒
  const defaultReminderList = filterAndSort(defaultList, keywords.appointmentMessageKeywords, true);
  const stdDefaultReminderList = defaultReminderList.map((item) => {
    return standardizeDefaultAction(item);
  });

  const generalActionList = filterAndSort(updatedList, keywords.excludeKeywords, false);
  // update 的預約提醒
  const updatedReminderList = filterAndSort(updatedList, keywords.appointmentMessageKeywords, true);
  const finalReminderList = combineReminderActionList(stdDefaultReminderList, updatedReminderList);

  return { generalActionList, finalReminderList };
};

const switchActionKeyword = (alias) => {
  switch (alias) {
    case 'ContentFormat0':
      return t('flexEditor.currentReminder');
    case 'ContentFormat1':
      return t('flexEditor.48hoursReminder');
    case 'ContentFormat5':
      return t('flexEditor.8amReminder');
    case 'ContentFormat7':
      return t('flexEditor.rescheduleRminder');
    default:
      return alias;
  }
};

export default function NestedList({
  lineActions,
  selectedAction,
  setSelectedAction,
  setWorkingMessage,
  setApplyStatus,
  vendor,
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [openAppointmentContent, setOpenAppointmentContent] = useState(true);

  const { generalActionList } = useMemo(() => filterAndSortAction(lineActions), [lineActions]);

  const { finalReminderList } = useMemo(
    () =>
      ['DrCooper', 'vintech', 'FIADigital', 'yousing', 'leyan'].includes(vendor)
        ? filterAndSortAction(lineActions)
        : {},
    [lineActions]
  );

  const handleExpand = () => {
    setOpen(!open);
  };

  const handleExpandAppointment = () => {
    setOpenAppointmentContent(!openAppointmentContent);
  };

  const handleListItemClick = (lineAction) => {
    setSelectedAction(lineAction);
    setWorkingMessage(lineAction.message);
    setApplyStatus(false);
  };

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader
          component="div"
          id="nested-list-subheader"
          className={classes.listSubheader}
          onClick={() => window.open('https://q.1talk.co/Q3wcx', '_blank')}
        >
          {t('flexEditor.AutoReplySetting')}
          <InfoPopover popoverContent={t('flexEditor.editAlleyPinLineAction')}></InfoPopover>
        </ListSubheader>
      }
      className={classes.root}
    >
      <ListItem button onClick={handleExpand}>
        <ListItemIcon>
          <InboxIcon />
        </ListItemIcon>
        <ListItemText primary={t('flexEditor.generalMessage')} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding dense>
          {generalActionList &&
            generalActionList.map((obj) => (
              <ListItem
                button
                className={classes.nested}
                onClick={() => handleListItemClick(obj)}
                selected={selectedAction === `${obj.id}`}
                key={`${obj.id}-${obj.alias}`}
              >
                <ListItemIcon>
                  <StarBorder />
                </ListItemIcon>
                <ListItemText primary={obj.alias} />
              </ListItem>
            ))}
        </List>
      </Collapse>
      {finalReminderList && (
        <ListItem button onClick={handleExpandAppointment}>
          <ListItemIcon>
            <SendIcon />
          </ListItemIcon>
          <ListItemText primary={t('flexEditor.appointmentReminderMessage')} />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      )}
      <Collapse in={openAppointmentContent} timeout="auto" unmountOnExit>
        <List component="div" disablePadding dense>
          {finalReminderList &&
            finalReminderList.map((obj) => (
              <ListItem
                button
                className={classes.nested}
                onClick={() => handleListItemClick(obj)}
                selected={selectedAction === `${obj.id}`}
                key={`${obj.id}-${obj.alias}`}
              >
                <ListItemIcon>
                  <StarBorder />
                </ListItemIcon>
                <ListItemText primary={switchActionKeyword(obj.alias)} />
              </ListItem>
            ))}
        </List>
      </Collapse>
    </List>
  );
}
